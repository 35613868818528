:root {
  --primary-color: #3dbcd2;
  --primary-color-hover: rgba(61, 188, 210, 0.75);
  --primary-color-dark: rgb(39, 152, 172);
  --bs-body-font-family: "Almarai", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  --bs-link-color: var(--bs-secondary-color);
  --bs-link-color-rgb: 33, 37, 41;
  --bs-link-decoration: none;
  --bs-link-hover-color: rgba(84, 89, 94, 0.75);
  --bs-link-hover-color-rgb: 84, 89, 94;
  --custom-black: #212529;
  --light-gray: #e6e6e6;
  --darkest-blue: #19657d;
}

.nav-link {
  --bs-nav-link-color: #212529;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--primary-color);
  --bs-btn-border-color: var(--primary-color);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--primary-color-hover);
  --bs-btn-hover-border-color: var(--primary-color-hover);
  --bs-btn-focus-shadow-rgb: 39, 152, 172;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--primary-color-dark);
  --bs-btn-active-border-color: var(--primary-color-dark);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--primary-color);
  --bs-btn-disabled-border-color: var(--primary-color);
}

.form-control {
  --bs-border-radius: 0;
}

h1 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  margin-bottom: 15px;
}

h2 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
}

.page {
  text-align: center;
  background-color: rgb(255, 255, 255);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--custom-black);
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 30px;
  max-width: 600px;
  margin: auto;
}

.page-section {
  padding-top: 65px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}

.eirfrost-image {
  width: 100%;
  height: 100%;
  min-height: 150px;
  position: relative;
}

.eirfrost-image-skeleton {
  width: 100%;
  height: 200px;
  position: relative;
}

.image-container {
  padding-left: 20px;
  padding-right: 40px;
  padding-bottom: 55px;
}

.image-container-left {
  padding-left: 40px;
  padding-right: 20px;
  padding-bottom: 55px;
}

.image-wire-right {
  position: relative;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}

.image-wire-right::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 380px;
  left: 25px;
  top: 25px;
  border: 5px solid var(--primary-color);
}

.image-wire-left {
  position: relative;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}

.image-wire-left::before {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 380px;
  height: 100%;
  left: -25px;
  top: 25px;
  border: 5px solid var(--primary-color);
}

.outline-section {
  text-align: center;
  width: 100%;
}

.outline-section-container {
  padding: 35px 25px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 8px 1px rgba(61, 188, 210, 0.5);
}

.eirfrost-button {
  width: 100%;
  padding: 16px;
  font-weight: 700;
}

.display-only-small {
  display: block;
}

.display-only-large {
  display: none;
}

@media (min-width: 992px) {
  .display-only-small {
    display: none;
  }

  .display-only-large {
    display: block;
  }

  h1 {
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 32px;
    letter-spacing: 0.64px;
    margin-bottom: 20px;
    line-height: normal;
  }

  .eirfrost-button {
    width: 280px;
  }

  .page {
    max-width: 960px;
    padding-top: 90px;
  }

  .page-section {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 8%;
    padding-top: 100px;
  }

  .page-right-section {
    width: 46%;
    order: 2;
  }

  .page-left-section {
    width: 46%;
    order: 1;
  }

  .image-container {
    padding-right: 50px;
    padding-bottom: 50px;
  }

  .image-container-left {
    padding-left: 50px;
    padding-bottom: 50px;
  }

  .image-wire-right {
    max-width: 500px;
  }

  .image-wire-left {
    max-width: 500px;
  }

  .image-wire-right::before {
    left: 40px;
    top: 40px;
    max-width: 500px;
    border: 7px solid var(--primary-color);
  }

  .image-wire-left::before {
    left: -40px;
    top: 40px;
    max-width: 500px;
    border: 7px solid var(--primary-color);
  }

  .outline-section-container {
    width: 100%;
  }

  .eirfrost-image-skeleton {
    height: 297px;
  }
}

@media (min-width: 1200px) {
  .page {
    max-width: 1140px;
  }

  .eirfrost-image-skeleton {
    height: 367px;
  }
}

@media (min-width: 1400px) {
  .page {
    max-width: 1320px;
  }

  .eirfrost-image-skeleton {
    height: 425px;
  }
}
