.contact-us-text {
  line-height: 25px;
}

.contact-info {
  margin: 30px 0;
}

.contact-info-section {
  margin-bottom: 22px;
}

.contact-info-text {
  margin-bottom: 8px;
}

.contact-info-strong {
  font-size: 18px;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.contact-info-link {
  text-decoration: none;
  margin-bottom: 20px;
}

.contact-info-icon {
  color: var(--primary-color);
  vertical-align: text-top;
  margin-right: 10px;
}

.contact-form-header {
  margin-bottom: 40px;
}

.contact-form-input {
  margin-bottom: 22px;
}

.contact-form-input label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
}

.contact-form-textarea {
  margin-bottom: 32px;
}

.contact-form-textarea label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
}

.form-control:focus {
  border-color: rgba(61, 188, 210, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(61, 188, 210, 0.6);
}

.contact-form-message {
  height: 110px !important;
}

@media (min-width: 992px) {
  .contact-form {
    padding: 50px;
  }

  .contact-us-section {
    width: 100%;
  }

  .contact-us-left {
    display: flex;
    flex-flow: column;
    row-gap: 15px;
  }

  .contact-us-details {
    order: 1;
  }

  .contact-us-image {
    order: 2;
  }

  .contact-from-button {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .contact-form-message {
    height: 160px !important;
  }
}

@media (min-width: 1400px) {
  .contact-form {
    padding: 70px;
  }

  .contact-form-message {
    height: 210px !important;
  }
}