.cookies-banner {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1040;
}

.cookies-banner-container {
  padding: 30px 60px;
}

.cookies-link {
  color: var(--primary-color);
}

.cookies-banner-text {
  text-align: left;
}

.cookie-image {
  margin-bottom: 16px;
}

.cookie-close {
  margin-bottom: 16px;
  cursor: pointer;
}

.cookies-header {
  text-align: end;
}

@media (min-width: 992px) {
  .cookies-banner {
    max-width: 884px;
    margin: 33px auto;
    bottom: 33px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .cookies-banner-container {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 30px 50px;
  }

  .cookies-header {
    order: 4;
  }

  .cookies-button {
    width: unset;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    padding: 14px 40px;
    margin-left: 10px;
  }

  .cookie-image {
    margin-bottom: 0;
  }

  .cookie-close {
    margin-bottom: 0;
    margin-left: 10px;
    height: 14px;
    width: 14px;
  }

  .cookies-banner-text {
    margin-bottom: 0;
  }
}
