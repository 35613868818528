.benefit {
  margin-top: 20px;
  margin-bottom: 10px;
}

.benefit-title {
  margin-top: 15px;
  color: var(--primary-color-dark);
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
}

.benefit-description {
  margin-top: 10px;
}

.benefit-icon {
  width: 40px;
  height: 40px;
}

@media (min-width: 768px) {
  .benefits-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin: auto;
    row-gap: 35px;
  }

  .benefit {
    width: 250px;
  }

  .benefit-icon {
    width: 50px;
    height: 50px;
  }

  .benefit-title {
    font-size: 26px;
    line-height: 30px;
  }
}

@media (min-width: 1200px) {
  .benefit {
    max-width: 200px;
  }
}

@media (min-width: 1400px) {
  .benefit {
    max-width: 230px;
  }
}