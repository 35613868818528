.bg-body-tertiary {
  background-color: white !important;
}

.navbar {
  --bs-navbar-brand-color: var(--primary-color);
  --bs-navbar-brand-hover-color: var(--primary-color-hover);
  --bs-navbar-toggler-border-color: transparent;
  padding-left: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--light-gray);
}

.offcanvas {
  --bs-offcanvas-padding-y: 20px;
  --bs-offcanvas-padding-x: 24px;
}

.navbar-body {
  font-size: 20px;
}

.navbar-button-container {
  margin-left: auto;
  margin-right: auto;
  padding-top: 12px;
}

.navbar-link {
  font-size: 20px;
  vertical-align: middle;
  padding-top: 8px;
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
}

.navbar-button {
  padding: 12px 16px !important;
  border-radius: 4px;
  line-height: 20px;
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;
}

.navbar-toggler-icon {
  opacity: 1;
  filter: invert(64%) sepia(29%) saturate(919%) hue-rotate(141deg)
    brightness(97%) contrast(86%);
}

.offcanvas-header .btn-close {
  width: 25px;
  height: 25px;
  background-size: 20px;
  opacity: 1;
  filter: invert(64%) sepia(29%) saturate(919%) hue-rotate(141deg)
    brightness(97%) contrast(86%);
}

.phone-icon {
  vertical-align: text-top;
  margin-right: 10px;
}

@media (min-width: 992px) {
  .navbar-nav {
    vertical-align: middle;
  }

  .navbar-link {
    margin-bottom: 0;
    padding-top: 12px;
    line-height: 47px;
  }

  .navbar-button {
    padding: 16px !important;
  }

  .phone-icon {
    vertical-align: initial;
    width: 16px;
    height: 16px;
  }
}

@media (min-width: 1200px) {
  .navbar-link {
    margin-left: 20px;
  }
}

@media (min-width: 1400px) {
  .navbar-link {
    margin-left: 40px;
  }
}