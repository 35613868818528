.our-work-section-container {
  padding-left: 0;
  padding-right: 0;
}

.our-work-padding {
  padding-left: 30px;
  padding-right: 30px;
}

.our-work-header {
  text-align: left;
}

.our-work-text {
  line-height: 25px;
  margin-bottom: 20px;
  text-align: left;
}

.our-work-examples {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 9px;
  margin-bottom: 30px;
}

.picture {
  height: 40%;
  width: 40%;
}

.picture-thumbnail {
  height: 100%;
  width: 100%;
}

.our-work-button {
  width: 100%;
}

@media (min-width: 992px) {
  .our-work-section-container {
    display: flex;
    flex-flow: row wrap;
    text-align: left;
    align-items: center;
    padding: 50px 50px 50px 20px;
    gap: 8%;
  }

  .our-work-examples {
    margin-bottom: 0;
  }

  .picture {
    height: 48%;
    width: 48%;
  }

  .picture-thumbnail {
    height: 100%;
    width: 100%;
  }

  .our-work-text {
    margin-bottom: 30px;
  }
}
