footer {
  margin-top: 65px;
  padding: 45px 24px 40px 24px;
  border-top: 1px solid var(--light-gray);
}

.footer-logo {
  width: 227px;
  height: 40px;
  margin-bottom: 18px;
}

.footer-element {
  padding-left: 0;
}

.footer-nav {
  flex-direction: column;
  gap: 17px;
}

.footer-nav-link {
  color: var(--custom-black);
}

.footer-link {
  padding: 0 0 8px 0;
  font-size: 16px;
  line-height: 18px;
}

.footer-section {
  padding-bottom: 27px;
  padding-left: 0;
}

.footer-text {
  padding-bottom: 4px;
}

.footer-contact-text {
  padding-bottom: 8px;
}

.footer-contact-link {
  text-decoration: none;
}

.footer-strong {
  color: var(--darkest-blue);
  font-size: 18px;
  padding-bottom: 8px;
}

.footer-contact-icon {
  color: var(--primary-color);
  vertical-align: text-top;
  margin-right: 10px;
}

@media (min-width: 992px) {
  footer {
    margin-top: 100px;
  }

  .footer-logo {
    width: 300px;
    height: 52px;
    margin-bottom: 18px;
  }

  .contact-form-button-resend {
    margin-top: 16px;
  }
}
