.gallery-picture-list {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  gap: 5px;
}

.gallery-picture-container {
  height: 46%;
  width: 46%;
}

.gallery-picture {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.gallery-picture-skeleton {
  height: auto;
  min-height: 130px;
  width: 100%;
}

@media (min-width: 992px) {
  .gallery-picture-list {
    gap: 10px;
  }

  .gallery-picture-container {
    height: 240px;
    width: 240px;
  }

  .gallery-picture {
    height: 240px;
    width: 240px;
  }

  .gallery-picture-skeleton {
    height: 240px;
    width: 240px;
  }
}
